import React, {useState} from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardSubtitle,
  CardText,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";

// core components
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";
import IndexNavbar from "components/Navbars/IndexNavbar";
import {APIRequest} from "utils/ApiRequest";
import {useNavigate} from "react-router";
import {toast} from 'react-toastify';
import {Link} from "react-router-dom";

function SponsorshipPage() {
  const [sponsorshipProducts, setSponsorshipProducts] = React.useState(null);
  const [selectedPrds, setSelectedPrds] = useState({});
  const [totalCartPrice, setTotalCartPrice] = useState(0);
  const [token, setToken] = useState(null);
  const [hideAlreadySponsored, setHideAlreadySponsored] = useState(true);
  const [utasvCount, setUtasvCount] = useState(0);
  const [bsCount, setBSCount] = useState(0);
  const history = useNavigate();

  React.useEffect(() => {
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    let userInfo = localStorage.getItem('user-info');
    if (userInfo !== null) {
      userInfo = JSON.parse(userInfo);
      if (userInfo.session !== null) {
        setToken(userInfo.session);
        loadProducts(userInfo.session);
        let sPrd = localStorage.getItem('selectedPrds');
        if (sPrd !== null) {
          setSelectedPrds(JSON.parse(sPrd));
        }
        let tcp = localStorage.getItem('totalCartPrice');
        setTotalCartPrice(tcp !== null ? parseInt(tcp) : 0);
        loadUtsavCount(userInfo.session);
        loadBSCount(userInfo.session);
      } else {
        history("/login")
      }
    } else {
      history("/login")
    }

    return function cleanup() {
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  function loadProducts(token) {
    APIRequest(
      token,
      "/getProducts",
      {},
      (res) => {
        setSponsorshipProducts(res.data.productsResponses["SPONSORSHIP"]);
      }
    );
  }

  function loadUtsavCount(token) {
    APIRequest(
      token,
      "/getUtsavUserCount",
      {},
      (res) => {
        setUtasvCount(res.data.count);
      },
      () => {
        setUtasvCount(0);
      }
    );
  }

  function loadBSCount(token) {
    APIRequest(
      token,
      "/getBijayaSanmelaniCount",
      {},
      (res) => {
        setBSCount(res.data.count);
      },
      () => {
        setBSCount(0);
      }
    );
  }

  const handleShowHideToggle = (e) => {
    setHideAlreadySponsored(!hideAlreadySponsored);
  }

  const handleSponsorClick = (event, fullSponsor, productId, code, price) => {
    let checked = event.target.checked;
    let tcp = 0;
    if (checked != null && checked) {
      if (!selectedPrds.hasOwnProperty(productId)) {
        let prdDetails = {};
        prdDetails["qty"] = 1;
        prdDetails["code"] = code;
        prdDetails["price"] = price;
        prdDetails["fullSponsor"] = fullSponsor;
        selectedPrds[productId] = prdDetails;
        tcp = totalCartPrice + price;
      }
    } else {
      if (selectedPrds.hasOwnProperty(productId)) {
        tcp = totalCartPrice - price;
        delete selectedPrds[productId];
      }
    }
    setSelectedPrds(selectedPrds);
    setTotalCartPrice(tcp);
    localStorage.removeItem('selectedPrds');
    localStorage.setItem('selectedPrds', JSON.stringify(selectedPrds));
    localStorage.removeItem('totalCartPrice');
    localStorage.setItem('totalCartPrice', tcp);
  }

  const handleReviewSelection = (e) => {
    e.preventDefault();
    let noOfAvailableTokens = utasvCount - bsCount;
    APIRequest(
      token,
      "/addToCart",
      JSON.parse('{"productQtys":' + JSON.stringify(selectedPrds) + ', "utsavMemberCount":' + noOfAvailableTokens + '}'),
      (res) => {
        history("/summary");
      },
      (err) => {
        try {
          if (err.status) {
            console.log(err.status, " ", err.error, " ", err.productId);
          }
          return toast.error(err.data.desc);
        } catch (error) {
          console.log(error);
          toast.error('Seems some internal issue with your network, please check your network!');
        }
      }
    );

  };

  return (
    <>
      <IndexNavbar/>
      <div className="wrapper">
        <LandingPageHeader/>
        <div className="section">
          <Container>
            <Row className="text-center">
              <Col className="text-center" md="12">
                <h2 className="title">Choose Sponsorship(s)</h2>
              </Col>
              <Card>
                <CardBody>
                  <blockquote className="blockquote blockquote-primary">
                    <span>
                      <b>Sponsorship is Optional</b>
                    </span>
                    <br/>
                    <span>
                      Your additional contribution matters to SORRBA
                    </span>
                    <br/>
                    {/* <footer className="blockquote-footer">
                      Team SORRBA
                    </footer> */}
                  </blockquote>
                </CardBody>
              </Card>
            </Row>
            <Row>
              <Col md="12">
                <Card border="primary" className="nav-tabs text-right" outline="true">
                  <Button className="btn-round" color="primary" onClick={(e) => {
                    handleShowHideToggle(e)
                  }} outline>
                    {!hideAlreadySponsored && <b>Hide already sponsored items</b>}
                    {hideAlreadySponsored && <b>Show all items</b>}
                  </Button>
                </Card>
              </Col>
            </Row>
            <Row>
              {sponsorshipProducts != null && sponsorshipProducts.map((mprd) => (
                (mprd.available || !hideAlreadySponsored) &&
                (mprd.available || !(mprd.sponsoredBy === undefined || mprd.sponsoredBy === null || mprd.sponsoredBy === "")) &&
                <Col md="6">
                  <Card border="primary" className="nav-tabs text-center" outline="true">
                    <CardBody>
                      <CardTitle tag="h4"><b>{mprd.name}</b></CardTitle>
                      {(mprd.desc !== undefined || mprd.desc !== null) &&
                        <CardSubtitle tag="h5">{mprd.desc}</CardSubtitle>
                      }
                      {mprd.available &&
                        <CardText>
                          <Row>

                            {!mprd.coSponsored &&
                              <Col>
                                <FormGroup check inline>
                                  {(selectedPrds === null
                                      || selectedPrds[mprd.id] === undefined
                                      || selectedPrds[mprd.id] === null
                                      || selectedPrds[mprd.id].fullSponsor === undefined
                                    )
                                    &&
                                    <Label check size="sm" color="primary">
                                      <Input bsSize="sm" type="checkbox"
                                             onClick={(e) => {
                                               handleSponsorClick(e, true, mprd.id, mprd.code, mprd.price)
                                             }}></Input>
                                      <span
                                        className="form-check-sign"><h6>Full Sponsor (&#x20B9;{mprd.price})</h6></span>
                                    </Label>
                                  }
                                  {selectedPrds !== null
                                    && selectedPrds[mprd.id] !== undefined
                                    && selectedPrds[mprd.id] !== null
                                    && selectedPrds[mprd.id].fullSponsor !== undefined
                                    && selectedPrds[mprd.id].fullSponsor !== null
                                    && !selectedPrds[mprd.id].fullSponsor
                                    &&
                                    <Label check size="sm" color="primary">
                                      <Input bsSize="sm" type="checkbox" disabled
                                             onClick={(e) => {
                                               handleSponsorClick(e, true, mprd.id, mprd.code, mprd.price)
                                             }}></Input>
                                      <span
                                        className="form-check-sign"><h6>Full Sponsor (&#x20B9;{mprd.price})</h6></span>
                                    </Label>
                                  }

                                  {selectedPrds !== null
                                    && selectedPrds[mprd.id] !== undefined
                                    && selectedPrds[mprd.id] !== null
                                    && selectedPrds[mprd.id].fullSponsor !== undefined
                                    && selectedPrds[mprd.id].fullSponsor !== null
                                    && selectedPrds[mprd.id].fullSponsor
                                    &&
                                    <Label check size="sm" color="primary">
                                      <Input type="checkbox" defaultChecked
                                             onClick={(e) => {
                                               handleSponsorClick(e, true, mprd.id, mprd.code, mprd.price)
                                             }}></Input>
                                      <span
                                        className="form-check-sign"><h6>Full Sponsor (&#x20B9;{mprd.price})</h6></span>
                                    </Label>
                                  }
                                </FormGroup>
                              </Col>
                            }
                            {!mprd.fullSponsorOnly &&
                              <Col>
                                <FormGroup check>
                                  {selectedPrds !== null
                                    && selectedPrds[mprd.id] !== undefined
                                    && selectedPrds[mprd.id] !== null
                                    && selectedPrds[mprd.id].fullSponsor !== undefined
                                    && selectedPrds[mprd.id].fullSponsor !== null
                                    && selectedPrds[mprd.id].fullSponsor
                                    &&
                                    <Label check size="sm">
                                      <Input type="checkbox" disabled
                                             onClick={(e) => {
                                               handleSponsorClick(e, false, mprd.id, mprd.code, mprd.coPrice)
                                             }}></Input>
                                      <span
                                        className="form-check-sign"><h6>Co-Sponsor (&#x20B9;{mprd.coPrice})</h6></span>
                                    </Label>
                                  }

                                  {selectedPrds !== null
                                    && selectedPrds[mprd.id] !== undefined
                                    && selectedPrds[mprd.id] !== null
                                    && selectedPrds[mprd.id].fullSponsor !== undefined
                                    && selectedPrds[mprd.id].fullSponsor !== null
                                    && !selectedPrds[mprd.id].fullSponsor
                                    &&
                                    <Label check size="sm">
                                      <Input type="checkbox" defaultChecked
                                             onClick={(e) => {
                                               handleSponsorClick(e, false, mprd.id, mprd.code, mprd.coPrice)
                                             }}></Input>
                                      <span
                                        className="form-check-sign"><h6>Co-Sponsor (&#x20B9;{mprd.coPrice})</h6></span>
                                    </Label>
                                  }

                                  {(selectedPrds === null
                                      || selectedPrds[mprd.id] === undefined
                                      || selectedPrds[mprd.id] === null
                                      || selectedPrds[mprd.id].fullSponsor === undefined
                                    )
                                    &&
                                    <Label check size="sm">
                                      <Input type="checkbox" onClick={(e) => {
                                        handleSponsorClick(e, false, mprd.id, mprd.code, mprd.coPrice)
                                      }}></Input>
                                      <span
                                        className="form-check-sign"><h6>Co-Sponsor (&#x20B9;{mprd.coPrice})</h6></span>
                                    </Label>
                                  }
                                </FormGroup>
                              </Col>
                            }
                          </Row>
                        </CardText>
                      }
                      {/* {!mprd.available && (mprd.sponsoredBy === undefined || mprd.sponsoredBy === null || mprd.sponsoredBy === "") &&
                        <CardFooter className="bg-warning text-white"><b>Not Available, Already Sponsored</b></CardFooter>
                      } */}
                      {!mprd.available && mprd.sponsoredBy !== undefined && mprd.sponsoredBy !== null && mprd.sponsoredBy !== "" &&
                        <CardFooter className="bg-warning text-white">Sponsored
                          by: <b>{mprd.sponsoredBy} (INR {mprd.price}) </b></CardFooter>
                      }
                    </CardBody>
                  </Card>
                </Col>
              ))}

            </Row>
            <Row>
              <Col md="12">
                <Card>
                  <CardBody>
                    <Row>
                      <Col md="8" className="text-left">
                        <CardText><h5><b>Total Amount: &#x20B9;{totalCartPrice}</b></h5>
                        </CardText>
                      </Col>
                      <Col md="4" className="text-right">
                        <Row>
                          <Col md="6">
                            <Button color="secondary" to="/membership" tag={Link}><b>Replan Membership</b></Button>
                          </Col>
                          <Col md="6">
                            <Button color="primary" onClick={(e) => {
                              handleReviewSelection(e)
                            }}><b>Review your Selection(s)</b></Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <DefaultFooter/>
      </div>
    </>
  );
}

export default SponsorshipPage;
