import React from "react";

// reactstrap components
import {Col, Container, Row,} from "reactstrap";

// core components
import DefaultFooter from "components/Footers/DefaultFooter.js";
import IndexNavbar from "components/Navbars/IndexNavbar";
import Durgostab2022PageHeader from "components/Headers/Durgostab2022PageHeader";

function Durgotsav2022Page() {

  React.useEffect(() => {
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <Durgostab2022PageHeader />
        <div className="section" data-background-color="black">
          <Container>
            <h2 className="title">Our Venue - 2023</h2>
            <Row>
              <Col md="12">
                <div class="iframe-embed-wrapper iframe-embed-responsive-16by9">
                  <iframe class="iframe-embed" frameborder="0" width={"100%"} src="https:&#x2F;&#x2F;www.canva.com&#x2F;design&#x2F;DAFMevt3vOI&#x2F;view?embed">
                  </iframe>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="section">
          <Container>
            <h2 className="title">Puja Schedules - 2023</h2>
            <Row>
              <Col md="12">
                <div class="iframe-embed-wrapper iframe-embed-responsive-16by9">
                  <iframe class="iframe-embed" frameborder="0" width={"100%"} src="https:&#x2F;&#x2F;www.canva.com&#x2F;design&#x2F;DAFMcoeOxa4&#x2F;view?embed">
                  </iframe>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
{/* 
        <div className="section" data-background-color="orange">
          <Container>
            <h2 className="title">Cultural Schedules - 2023</h2>
            <Row>
              <Col md="12">
                <div class="iframe-embed-wrapper iframe-embed-responsive-16by9">
                  <iframe class="iframe-embed" frameborder="0" width={"100%"} src="https:&#x2F;&#x2F;www.canva.com&#x2F;design&#x2F;DAFNgc8E7H4&#x2F;view?embed">
                  </iframe>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
         */}
        <div className="section" data-background-color="black">
          <Container>
            <h3 className="title">Corporate Engagement</h3>
            <Row>
              <Col md="12">
                <div class="iframe-embed-wrapper iframe-embed-responsive-16by9">
                  <iframe class="iframe-embed" frameborder="0" width={"100%"} src="https:&#x2F;&#x2F;www.canva.com&#x2F;design&#x2F;DAERT02vkK4&#x2F;view?embed">
                  </iframe>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        
        <DefaultFooter />
      </div>
    </>
  );
}

export default Durgotsav2022Page;
